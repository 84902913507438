<template>
  <b-card no-body class="card-apply-job h-100">
    <b-card-body>
      <b-media no-body>
        <b-media-aside class="mr-05">
          <!-- IF DESCRIPTION IS NOT CHECKED -->
          <div v-if="!checked">
            <i class="bi bi-person-circle fa-3x text-secondary"></i>
          </div>

          <!-- IF DESCRIPTION IS CHECKED -->
          <div v-else-if="checked">
            <b-avatar
              size="100"
              src="@/assets/images/portrait/small/avatar-s-20.jpg"
            />
          </div>
        </b-media-aside>
        <b-media-body class="ml-2">
          <div class="d-flex">
            <!-- IF DESCRIPTION IS CHECKED -->
            <div v-if="checked" class="d-flex">
              <b-card-title class="mb-1">Siti Ayu Jannah</b-card-title>
              <div class="ml-1 clickable" style="margin-top: -5px">
                <feather-icon
                  icon="EditIcon"
                  size="16"
                  v-b-tooltip.hover.v-dark="'Edit'"
                />
              </div>
            </div>

            <!-- IF DESCRIPTION IS NOT CHECKED -->
            <div v-else class="d-flex">
              <b-card-title style="margin-top: 20px"
                >Siti Ayu Jannah</b-card-title
              >
              <div class="ml-1 mt-1 clickable">
                <feather-icon icon="EditIcon" size="16" v-b-tooltip.hover.v-dark="'Edit'"/>
              </div>
            </div>
            <div class="ml-auto d-flex float-right">
              <p class="mr-1">Deskripsi Wikipedia</p>
              <!-- TOGGLE DESCRIPTION BUTTON -->
              <b-form-checkbox
                v-model="checked"
                checked="true"
                class="custom-control-primary"
                name="check-button"
                switch
              >
              </b-form-checkbox>
            </div>
          </div>
          <!-- NARASUMBER DESCRIPTION -->
          <p v-if="checked" class="text-justify mr-05">
            My name is Siti Ayu Jannah Lorem ipsum dolor sit amet consectetur
            adipisicing elit. Amet aminus impedit ipsam praesentium alias? Nemo
            magni dignissimos sit,odit nesciunt ipsam iusto, velit quod
            consectetur soluta, commodisaepe iure.Lorem ipsum dolor sit amet
            consectetur adipisicing elit.Amet ab minus impedit ipsam praesentium
            alias.
          </p>
        </b-media-body>
      </b-media>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardBody,
  BCardTitle,
  BMedia,
  BMediaAside,
  BMediaBody,
  BAvatar,
  BFormCheckbox,
  VBTooltip,
} from "bootstrap-vue";

export default {
  name: "cardProfile",
  components: {
    BCard,
    BCardBody,
    BCardTitle,
    BMedia,
    BMediaAside,
    BMediaBody,
    BAvatar,
    BFormCheckbox,
    VBTooltip,
  },

  data() {
    return {
      checked: "true",
    };
  },

  directives: {
    "b-tooltip": VBTooltip,
  },
};
</script>
