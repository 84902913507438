<template>
    <div>
      <div class="row mb-2">
        <!-- LIST NARASUMBER -->
        <div class="col-md">
          <cardListNarasumber />
        </div>
  
        <!-- CONTENT OF NARASUMBER DATA -->
        <div class="col-md-9 h-100">
          <!-- NARASUMBER PROFILE -->
          <div class="row">
            <div class="col-md-12">
              <div>
                <cardProfile />
              </div>
            </div>
          </div>
  
          <!-- ROW 1 -->
          <div class="row" style="margin-bottom: 2rem">
            <!-- TOPIK PEMBERITAAN -->
            <div class="col-md-7">
              <!-- ENTITY NOT SELECTED -->
              <!-- <alertWarning
                :title="'Topik Pemberitaan'"
                :subtitle="'*Silahkan Pilih Entitas Terlebih Dahulu'"
              /> -->
  
              <!-- DATA IS EMPTY -->
              <!-- <alertWarning
                :title="'Topik Pemberitaan'"
                :subtitle="'Data Tidak Tersedia !'"
              /> -->
  
              <!-- DATA AVAILABLE -->
              <cardTopikPemberitaan />
            </div>
  
            <!-- SENTIMEN PEMBERITAAN -->
            <div class="col-md-5">
              <!-- ENTITY NOT SELECTED -->
              <!-- <alertWarning
                :title="'Sentimen Pemberitaan'"
                :subtitle="'*Silahkan Pilih Entitas Terlebih Dahulu'"
              /> -->
  
              <!-- DATA IS EMPTY -->
              <!-- <alertWarning
                :title="'Sentimen Pemberitaan'"
                :subtitle="'Data Tidak Tersedia !'"
              /> -->
  
              <!-- DATA AVAILABLE -->
              <cardSentimenPemberitaan />
            </div>
          </div>
  
          <!-- ROW 2 -->
          <div class="row mb-2">
            <!-- TREN PEMBERITAAN -->
            <div class="col-md-7">
              <!-- ENTITY NOT SELECTED -->
              <!-- <alertWarning
                :title="'Tren Pemberitaan'"
                :subtitle="'*Silahkan Pilih Entitas Terlebih Dahulu'"
              /> -->
  
              <!-- DATA IS EMPTY -->
              <!-- <alertWarning
                :title="'Tren Pemberitaan'"
                :subtitle="'Data Tidak Tersedia !'"
              /> -->
  
              <!-- DATA AVAILABLE -->
              <cardTrenPemberitaan />
            </div>
  
            <!-- EMOTION PEMBERITAAN -->
            <div class="col-md-5">
              <!-- ENTITY NOT SELECTED -->
              <!-- <alertWarning
                :title="'Emotion Pemberitaan'"
                :subtitle="'*Silahkan Pilih Entitas Terlebih Dahulu'"
              /> -->
  
              <!-- DATA IS EMPTY -->
              <!-- <alertWarning
                :title="'Emotion Pemberitaan'"
                :subtitle="'Data Tidak Tersedia !'"
              /> -->
  
              <!-- DATA AVAILABLE -->
              <cardEmotionPemberitaan />
            </div>
          </div>
  
          <!-- BERITA TERKAIT -->
          <div class="berita-terkait">
            <h3>Berita Terkait</h3>
            <div v-for="item in item_pages" :key="item.name">
              <newsMedsosCard />
            </div>
  
            <!-- PAGINATION -->
            <b-pagination
              v-model="current_page"
              :total-rows="items.length"
              :per-page="per_page"
              align="right"
              size="sm"
              @change="updatePage"
            >
            </b-pagination>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { BPagination } from "bootstrap-vue";
  import cardListNarasumber from "@/components/analytic/narasumber/cardListNarasumber.vue";
  import cardProfile from "@/components/analytic/narasumber/cardProfile.vue";
  import cardTopikPemberitaan from "@/components/analytic/narasumber/cardTopikPemberitaan.vue";
  import cardSentimenPemberitaan from "@/components/analytic/narasumber/cardSentimenPemberitaan.vue";
  import cardTrenPemberitaan from "@/components/analytic/narasumber/cardTrenPemberitaan.vue";
  import cardEmotionPemberitaan from "@/components/analytic/narasumber/cardEmotionPemberitaan.vue";
  import newsMedsosCard from "@/components/usable/newsMedsosCard.vue";
  import alertWarning from "@/components/usable/alertWarning.vue";
  
  export default {
    name: "Narasumber",
    components: {
      BPagination,
      cardListNarasumber,
      newsMedsosCard,
      cardProfile,
      cardTopikPemberitaan,
      cardSentimenPemberitaan,
      cardTrenPemberitaan,
      cardEmotionPemberitaan,
      alertWarning,
    },
  
    data() {
      return {
        title: "Narasumber | Online Media Monitoring",
        current_page: 1,
        per_page: 3,
        item_pages: [],
        items: [
          {
            name: "1",
            checked: false,
          },
          {
            name: "2",
            checked: false,
          },
          {
            name: "3",
            checked: false,
          },
          {
            name: "4",
            checked: false,
          },
          {
            name: "5",
            checked: false,
          },
          {
            name: "6",
            checked: false,
          },
          {
            name: "7",
            checked: false,
          },
          {
            name: "8",
            checked: false,
          },
          {
            name: "9",
            checked: false,
          },
          {
            name: "10",
            checked: false,
          },
          {
            name: "11",
            checked: false,
          },
          {
            name: "12",
            checked: false,
          },
          {
            name: "13",
            checked: false,
          },
          {
            name: "14",
            checked: false,
          },
        ],
      };
    },
  
    created() {
      document.title = this.title;
    },
  
    mounted() {
      this.updateItemPages();
    },
  
    methods: {
      // UPDATE PAGE
      updatePage(page_number) {
        this.current_page = page_number;
        this.updateItemPages();
      },
      // UPDATE ITEMS OF PAGE
      updateItemPages() {
        this.item_pages = this.items.slice(
          (this.current_page - 1) * this.per_page,
          (this.current_page - 1) * this.per_page + this.per_page
        );
        if (this.item_pages.length == 0 && this.current_pages == 0) {
          this.updatePage(this.current_pages - 1);
        }
      },
    },
  };
  </script>