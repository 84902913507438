<template>
  <b-card no-body class="h-100">
    <b-card-header>
      <b-card-title> Daftar Narasumber </b-card-title>
    </b-card-header>
    <b-card-body :class="selected != '' ? 'scroller' : ''">
      <div class="d-flex mb-3">
        <!-- FORM SEARCH -->
        <b-input-group class="input-group-merge">
          <b-input-group-prepend is-text>
            <feather-icon icon="SearchIcon" class="text-primary" />
          </b-input-group-prepend>
          <b-form-input v-model="filter" placeholder="Cari Entitas" />
        </b-input-group>

        <!-- PLUS BUTTON -->
        <b-button
          size="sm"
          class="ml-1"
          variant="outline-success"
          v-b-tooltip.hover.v-dark="'Tambah Narasumber'"
        >
          <feather-icon icon="PlusIcon" />
        </b-button>
      </div>

      <!-- LIST ENTITY -->
      <div class="scroll-entitas">
        <div v-for="user in searchUser" :key="user.id" class="tombol clickable">
          <div
            @click="getDataCard(user.id)"
            class="mb-1 rounded"
            style="padding: 5px"
            :class="{ active: selected == user.id }"
          >
            <b-media no-body>
              <b-media-aside>
                <!-- NARASUMBER PROFILE -->
                <b-avatar
                  size="45"
                  class="text-success bg-light-success"
                  style="font-size: 20px"
                >
                  {{ user.profile }}
                </b-avatar>
              </b-media-aside>
              <b-media-body>
                <!-- NARASUMBER NAME -->
                <h5>{{ user.nama }}</h5>

                <!-- NARASUMBER ALIAS -->
                <small>Alias: {{ user.alias }}</small>
              </b-media-body>
            </b-media>
          </div>
        </div>
      </div>
    </b-card-body>
  </b-card>
</template>
  <script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
  BFormGroup,
  BAvatar,
  BMedia,
  BMediaAside,
  BMediaBody,
  BButton,
  VBTooltip,
} from "bootstrap-vue";

export default {
  name: "cardListNarasumber",
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BFormGroup,
    BAvatar,
    BMedia,
    BMediaAside,
    BMediaBody,
    BButton,
    VBTooltip,
  },

  data: () => ({
    filter: "",
    selected: "",
    userData: {},
    option: [
      {
        title: "Option1",
      },
      {
        title: "Option2",
      },
      {
        title: "Option3",
      },
    ],
    users: [
      {
        id: 1,
        profile: "SA",
        image: require("@/assets/images/portrait/small/avatar-s-20.jpg"),
        nama: "Siti Ayu Jannah",
        alias: "Ayu J, Siti AJ, Ayu Jannah",
        deskripsi:
          "My name is Siti Ayu Jannah Lorem ipsum dolor sit amet consectetur adipisicing elit. Amet aminus impedit ipsam praesentium alias? Nemo magni dignissimos sit,odit nesciunt ipsam iusto, velit quod consectetur soluta, commodisaepe iure.Lorem ipsum dolor sit amet consectetur adipisicing elit.Amet ab minus impedit ipsam praesentium alias.",
      },
      {
        id: 2,
        profile: "RB",
        image: require("@/assets/images/avatars/10-small.png"),
        nama: "Rich Brocklesnar",
        alias: "Rich B",
        deskripsi:
          "My name is Rich Brock Lorem ipsum dolor sit amet consectetur adipisicing elit. Amet aminus impedit ipsam praesentium alias? Nemo magni dignissimos sit,odit nesciunt ipsam iusto, velit quod consectetur soluta, commodisaepe iure.Lorem ipsum dolor sit amet consectetur adipisicing elit.Amet ab minus impedit ipsam praesentium alias.",
      },
      {
        id: 3,
        profile: "RB",
        image: require("@/assets/images/avatars/10-small.png"),
        nama: "Rich Brocklesnar",
        alias: "Rich B",
        deskripsi:
          "My name is Rich Brock Lorem ipsum dolor sit amet consectetur adipisicing elit. Amet aminus impedit ipsam praesentium alias? Nemo magni dignissimos sit,odit nesciunt ipsam iusto, velit quod consectetur soluta, commodisaepe iure.Lorem ipsum dolor sit amet consectetur adipisicing elit.Amet ab minus impedit ipsam praesentium alias.",
      },
      {
        id: 4,
        profile: "RB",
        image: require("@/assets/images/avatars/10-small.png"),
        nama: "Rich Brocklesnar",
        alias: "Rich B",
        deskripsi:
          "My name is Rich Brock Lorem ipsum dolor sit amet consectetur adipisicing elit. Amet aminus impedit ipsam praesentium alias? Nemo magni dignissimos sit,odit nesciunt ipsam iusto, velit quod consectetur soluta, commodisaepe iure.Lorem ipsum dolor sit amet consectetur adipisicing elit.Amet ab minus impedit ipsam praesentium alias.",
      },
      {
        id: 5,
        profile: "RB",
        image: require("@/assets/images/avatars/10-small.png"),
        nama: "Rich Brocklesnar",
        alias: "Rich B",
        deskripsi:
          "My name is Rich Brock Lorem ipsum dolor sit amet consectetur adipisicing elit. Amet aminus impedit ipsam praesentium alias? Nemo magni dignissimos sit,odit nesciunt ipsam iusto, velit quod consectetur soluta, commodisaepe iure.Lorem ipsum dolor sit amet consectetur adipisicing elit.Amet ab minus impedit ipsam praesentium alias.",
      },
      {
        id: 6,
        profile: "RB",
        image: require("@/assets/images/avatars/10-small.png"),
        nama: "Rich Brocklesnar",
        alias: "Rich B",
        deskripsi:
          "My name is Rich Brock Lorem ipsum dolor sit amet consectetur adipisicing elit. Amet aminus impedit ipsam praesentium alias? Nemo magni dignissimos sit,odit nesciunt ipsam iusto, velit quod consectetur soluta, commodisaepe iure.Lorem ipsum dolor sit amet consectetur adipisicing elit.Amet ab minus impedit ipsam praesentium alias.",
      },
      {
        id: 7,
        profile: "RB",
        image: require("@/assets/images/avatars/10-small.png"),
        nama: "Rich Brocklesnar",
        alias: "Rich B",
        deskripsi:
          "My name is Rich Brock Lorem ipsum dolor sit amet consectetur adipisicing elit. Amet aminus impedit ipsam praesentium alias? Nemo magni dignissimos sit,odit nesciunt ipsam iusto, velit quod consectetur soluta, commodisaepe iure.Lorem ipsum dolor sit amet consectetur adipisicing elit.Amet ab minus impedit ipsam praesentium alias.",
      },
      {
        id: 8,
        profile: "RB",
        image: require("@/assets/images/avatars/10-small.png"),
        nama: "Rich Brocklesnar",
        alias: "Rich B",
        deskripsi:
          "My name is Rich Brock Lorem ipsum dolor sit amet consectetur adipisicing elit. Amet aminus impedit ipsam praesentium alias? Nemo magni dignissimos sit,odit nesciunt ipsam iusto, velit quod consectetur soluta, commodisaepe iure.Lorem ipsum dolor sit amet consectetur adipisicing elit.Amet ab minus impedit ipsam praesentium alias.",
      },
      {
        id: 9,
        profile: "RB",
        image: require("@/assets/images/avatars/10-small.png"),
        nama: "Rich Brocklesnar",
        alias: "Rich B",
        deskripsi:
          "My name is Rich Brock Lorem ipsum dolor sit amet consectetur adipisicing elit. Amet aminus impedit ipsam praesentium alias? Nemo magni dignissimos sit,odit nesciunt ipsam iusto, velit quod consectetur soluta, commodisaepe iure.Lorem ipsum dolor sit amet consectetur adipisicing elit.Amet ab minus impedit ipsam praesentium alias.",
      },
    ],
  }),

  methods: {
    // GET USER DATA
    getDataCard(id) {
      this.selected = id;
      this.userData = this.users.find((el) => el.id == id);
      this.filter = "";
    },
  },

  computed: {
    // SEARCH USER TO SELECT
    searchUser() {
      return this.users.filter((user) => {
        let response =
          user.nama.toLowerCase().match(this.filter.toLowerCase()) ||
          user.alias.toLowerCase().match(this.filter.toLowerCase());
        return response;
      });
    },
  },

  directives: {
    "b-tooltip": VBTooltip,
  },
};
</script>
<style scoped>
.tombol :hover {
  background-color: #ecf5f8;
  border-radius: 5px;
}
.dark-layout * .tombol :hover {
  background-color: #202940;
  border-radius: 5px;
}
.active {
  background-color: #d3eeff;
}
.dark-layout * .active {
  background-color: #161d31;
}
.scroller .scroll-entitas {
  display: block !important;
  height: auto !important;
  max-height: 125rem;
  overflow: scroll !important;
}
.scroll-entitas {
  display: block;
  height: 40rem;
  overflow: scroll;
}
.scroll-entitas::-webkit-scrollbar {
  width: 10px;
  height: 0px;
}
.scroll-entitas::-webkit-scrollbar-thumb {
  background-color: #d3eeff;
  border-radius: 10px;
}

.scroll-entitas::-webkit-scrollbar-track {
  background-color: #ecf5f8;
  border-radius: 10px;
}
.scroll-entitas::-webkit-scrollbar-corner {
  background-color: #d3eeff;
  border-radius: 10px;
}
.dark-layout * .scroll-entitas::-webkit-scrollbar-thumb {
  background-color: #343b51;
  border-radius: 10px;
}
.dark-layout * .scroll-entitas::-webkit-scrollbar-track {
  background-color: #202940;
  border-radius: 10px;
}
.dark-layout * .scroll-entitas::-webkit-scrollbar-corner {
  background-color: #343b51;
  border-radius: 10px;
}
</style>