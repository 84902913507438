<template>
  <b-card no-body class="h-100">
    <b-card-header class="mb-1">
      <b-card-title> Emotion Pemberitaan </b-card-title>
    </b-card-header>
    <b-card-body>
      <!-- PIE CHART COMPONENT -->
      <pieChart :data="chart_data" />
    </b-card-body>
  </b-card>
</template>
<script>
import { BCard, BCardHeader, BCardTitle, BCardBody } from "bootstrap-vue";
import pieChart from "@/components/usable/pieChart.vue";

export default {
  name: "cardEmotionPemberitaan",
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    pieChart,
  },

  data() {
    return {
      chart_data: {
        colors: ["#82868B", "#28C76F", "#FF9F43", "#F26322", "#EA5455"],
        legend: true,
        labels: ["No Emotion", "Senang", "Takut", "Sedih", "Marah"],
        series: [2, 2, 5, 8, 9],
      },
    };
  },
};
</script>