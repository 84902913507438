<template>
  <b-card no-body class="h-100">
    <b-card-header class="mb-2">
      <b-card-title> Tren Pemberitaan </b-card-title>
    </b-card-header>
    <b-card-body>
      <!-- LINE CHART COMPONENT -->
      <lineChart :data="chart_data" />
    </b-card-body>
  </b-card>
</template>
<script>
import { BCard, BCardHeader, BCardTitle, BCardBody } from "bootstrap-vue";
import { $themeColors } from "@themeConfig";
import lineChart from "@/components/usable/lineChart.vue";

export default {
  name: "cardTrenPemberitaan",
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    lineChart,
  },
  
  data() {
    return {
      chart_data: {
        colors: [
          $themeColors.success,
          $themeColors.primary,
          $themeColors.danger,
        ],
        categories: ["17", "18", "19", "20", "21", "22", "23"],
        series: [
          {
            name: "Positif",
            data: [45000, 47000, 47800, 49500, 45500, 48000, 46500, 48600],
          },
          {
            name: "Netral",
            data: [45000, 46000, 45500, 44600, 44500, 46500, 45000, 47000],
          },
          {
            name: "Negatif",
            data: [45000, 42000, 41500, 43600, 44500, 46500, 45000, 47000],
          },
        ],
      },
    };
  },
};
</script>